.App {
  text-align: center;
  background-color: #140101;
  background-image: url("./images/bg.jpg");
  min-height: 1000px;
  background-repeat: repeat;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 50px;
}
.App-header a {
  text-decoration: none;
  color: white;
}

.App-link {
  color: #61dafb;
}
.family-picture {
  border-radius: 50%;
}
.nav-bar {
  margin-top: 10px;
  margin-bottom: 100px;
  color: white;
}

.nav-bar a {
  padding: 50px;
  text-decoration: none;
  color: white;
}

footer {
  margin-top: 100px;
  height: 80px;
  color: white;
}

.name {
  color: white;
  font-size: 24px;
}

.member-pic {
  width: 543px;
}
.king-pic {
  width: 1000px;
}

.gang-box {
  margin: 20px;
  display: inline-block;
}
